import { QuestionMark } from '@mui/icons-material'
import { Box, Button, Divider, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { Colors } from '../../constants/color'

function WhyChooseUs() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [elements, setElements] = useState([
        {
            title: "Recruit Smarter, Not Harder",
            subTitle: "MBAhires.com empowers data-driven recruitment with real-time insights at every turn. Explore diverse candidate profiles, benchmark competitive compensation, and receive expert hiring tips.",
            image: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/recruit_smarter.png",
            mobileImage:"https://ccicons.s3.amazonaws.com/mba_hires/landing_page/recruit_smarter_mobile.jpg",
            align: "row",
        },
        {
            title: "Reach 100+ Campuses Instantly",
            subTitle: "Post a job to reach 100+ campuses and connect with interested students. MBAhires.com bridges the gap between you and colleges, saving time and resources to attract top talent.",
            image: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/reach_100.png",
            mobileImage:"https://ccicons.s3.amazonaws.com/mba_hires/landing_page/reach_100_mobile.jpg",
            align: "row-reverse",
        },
        {
            title: "Auto-Generated JDs",
            subTitle: "Poorly written job descriptions deter qualified candidates. Use our AI-powered descriptions for over 42 roles to ensure clarity, attract top talent, and enhance recruitment efforts.",
            image: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/auto_generated_jds.gif",
            mobileImage:"https://ccicons.s3.amazonaws.com/mba_hires/landing_page/auto_generated_jds_mobile.gif",
            align: "row",
        },
        {
            title: "Smooth Interface",
            subTitle: "Forget tech hassles! MBAhires.com makes recruiting easy. New to platforms? No problem! Post jobs, shortlist candidates, send offers, and schedule interviews with our intuitive interface.",
            image: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/smooth_interface.png",
            mobileImage:"https://ccicons.s3.amazonaws.com/mba_hires/landing_page/smooth_interface_mobile.jpg",
            align: "row-reverse",
        },
    ])

    return (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"} mt={{xs:0,md:5}} bgcolor={"white"}>
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} my={{xs:0,md:8}}>
                <Typography variant='h5' color={"text.secondary"} display={"inline"} sx={{color:{xs:"text.primary"}}} fontWeight={{xs:400}} fontSize={{xs:"20px"}}>Why Choose MBAHires.com </Typography>
                <QuestionMark sx={{ display: "inline" }} fontSize='large' mx={0.5} color={"primary"} />
            </Box>
            {elements.map((element, index) =>
                <Box
                    key={index}
                    display={"flex"}
                    flexDirection={{ xs: "column-reverse", md: element.align }}
                    mx={{ xs: 2, md: 5 }}
                    justifyContent={{ xs: "center", md: "space-between" }}
                    alignItems={"center"}
                    my={{ xs: 2, md: 4 }}
                >
                    <Box width={{ xs: "100%", md: "50%" }} textAlign={{ xs: "center", md: "left" }}>
                        <Box display={"flex"} justifyContent={"flex-start" }>
                            <Divider orientation={"vertical"} flexItem sx={{ borderColor: Colors.primary, borderWidth: 1 }} />
                            <Typography variant={"h5"} fontWeight={"600"} fontSize={"20px"} mx={1}>{element.title}</Typography>
                        </Box>
                        <Typography variant='body1' color={"text.secondary"} my={1} textAlign={{xs:"left"}} fontSize={{xs:"18px"}}>{element.subTitle}</Typography>
                        <Box display={"flex"} justifyContent={"center"} mt={"16px"}>
                            <Button
                                href={"https://recruiter.careercarve.in"}
                                sx={{
                                    textTransform: "none",
                                    px: "48px", py: "16px", borderRadius: 10, '&:hover': {
                                        backgroundColor: '#d35452', // Darker shade for hover state
                                    },
                                }}>
                                <Typography variant='body2' fontWeight={"medium"}>Get Started</Typography>
                            </Button>
                        </Box>
                    </Box>
                    <Box display={"flex"} justifyContent={"center"} mb={{xs:1,md:0}}>
                        <Box component={"img"} src={isMobile ? element.mobileImage : element.image} sx={{ width: { xs: "100%", md: "390px" }, height: "auto" }} />
                    </Box>
                </Box>
            )}
        </Box>
    )
}

export default WhyChooseUs