import React from 'react'
import Hero from './Hero'
import Clients from './Clients'
import CardContainer from './CardContainer'
import FasterHiring from './FasterHiring'
import WhyChooseUs from './WhyChooseUs'
import UsVsOthers from './UsVsOthers'
import PartnerBrands from './PartnerBrands'
import TestTopics from './TestTopics'
import { Helmet } from 'react-helmet'

function Landing() {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MBAhires | Your trusted campus hiring partner | Hire fresh MBAs from 100+ colleges faster and smarter</title>
        <meta
          name="description"
          content="We help companies hire fresh MBA graduates from top MBA colleges in India with precision. Our rigorous assessment ensures the best shortlists, our AI-powered JD and industry trends help post a job in minutes, and our high-end tech reduces hiring time by 90%. Outsource your MBA campus hiring NOW"
        />
        <meta
          name="keywords"
          content="MBA campus hiring, MBA campus recruitment, MBA Summer Intern, Hire MBAs, Hire management graduates"
        />
      </Helmet>
      <Hero />
      <Clients />
      <CardContainer />
      <FasterHiring />
      <WhyChooseUs />
      <UsVsOthers />
      <PartnerBrands />
      {/* <TestTopics /> */}
    </React.Fragment>
  )
}

export default Landing