
import { Box, Card, CardContent, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Colors } from '../../constants/color'

function FasterHiring() {
    const [cards, setCards] = useState([
        {
            name: "Rapid  Talent  Matching",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/rapid_talent.svg",
            small_icon: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/rapid_talent_small.svg",
            color: "linear-gradient(300.18deg, #5BC8F8 5.68%, #2EB9F5 20.3%, #0596D5 39.42%, #1184B5 65.79%)"
        },
        {
            name: "AI-Powered  Job  Description",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/ai_powered.svg",
            small_icon: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/ai_powered_small.svg",
            color: "linear-gradient(293.55deg, #8D7BFF 2.04%, #5A4AC1 35.97%, #3B2C98 99.58%)"
        },
        {
            name: "Data-Driven Decision Making",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/data_driven.svg",
            small_icon: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/data_driven_small.svg",
            color: "linear-gradient(295.79deg, #01A088 2.43%, #078774 31.27%, #003A31 97.57%)"
        },
        {
            name: "Seamless Interview Scheduling",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/seamless_interview.svg",
            small_icon: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/seamless_interview_small.svg",
            color: "linear-gradient(290.25deg, #FFA349 0.28%, #E08227 39.41%, #B66414 99.72%)"
        },
        {
            name: "Resume Lightening",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/resume_lightening.svg",
            small_icon: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/resume_lightening_small.svg",
            color: "linear-gradient(290.59deg, #E56E6A 0.04%, #D55A55 42.61%, #A53935 99.96%)"
        },
    ])
    return (
        <Box>
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"} mt={{xs:8,md:4}}>
                <Typography variant={{xs:"h6",md:"h4"}} color={"text.secondary"} fontSize={{xs:"16px",md:"auto"}} mt={2} mb={1} >Fast-track Your Recruitment Game</Typography>
                <Typography variant={{xs:"h5",md:"h4"}} fontSize={{xs:"18px",md:"auto"}} display={"inline"}>Get <Typography variant={{xs:"h5",md:"h4"}} display={"inline"}  fontWeight={{xs:"500"}} color={Colors.primary}>10X Faster Hiring </Typography>MBAhires</Typography>
                
                <Box display={"flex"} flexWrap={"wrap"} justifyContent={"center"} mb={8}>
                    {cards.map((card) =>
                        <Card sx={{ background: card.color, borderRadius: "16px", mx:{xs:1,md:8}, my: {xs:1,md:2}, alignItems:"center", display:"flex",position:"relative"}}  >
                            <Box sx={{ display: "flex", alignItems: {xs:"flex-start",md:"center"}, px: 1, py: 1, gap: {xs:3,md:8}, justifyContent: "space-between" }} flexDirection={{xs:"column",md:"row"}} width={{xs:"169px",md:"auto"}} height={{xs:"169px",md:"auto"}}>
                            <Box
                                    component="img"
                                    sx={{
                                        position:"absolute",
                                        bottom:-5,
                                        left:0

                                    }}
                                    src={card.small_icon}
                                />
                                <Typography variant="body1" color={"white"} ml={1} width={{xs:"150px",md:"auto"}} fontWeight={{xs:"600"}} fontSize={{xs:"18px",md:"auto"}}>
                                    {card.name}
                                </Typography>
                                <Box
                                    component="img"
                                    sx={{
                                        alignSelf: { xs: "flex-end", md: "flex-start" },
                                        width: {xs:"80px",md:"48px"}, /* you can use % */
                                        height: {xs:"73px",md:"auto"},
                                        position: { xs: "absolute", md: "static" },
                                        bottom: { xs: 5, md: "auto" },
                                        right: { xs: 5, md: "auto" }
                                    }}
                                    src={card.icon}
                                />
                            </Box>
                        </Card>)}
                </Box>
            </Box>
        </Box>
    )
}

export default FasterHiring