import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import { Colors } from '../../constants/color'
import './Clients.css'

function Clients() {
  return (
    <Box>
      <Box overflow={"hidden"} height={"200px"}>
        <Box overflow={"hidden"} sx={{ height: "979px", width: {xs:"180vw", md:"130vw"}, borderRadius: {xs:"50%/20%",md:"50%"}, backgroundColor: Colors.primaryLight, transform: { xs: "translate(-22%, 0%)", md: "translate(-12%, 0%)" }, boxShadow: `${Colors.primaryLight} 2px 4px 10px, ${Colors.primaryLight} 2px 2px 15px`  }} display={"flex"} justifyContent={"center"}>
          <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} mt={4} >
            <Typography textAlign={"center"} color={"text.secondary"} variant='body1' display={"inline"} fontSize={{xs:"16px"}}>Gain Visibility Across<Typography display={"inline"} variant='h4' fontSize={{xs:"18px"}}>{" 100+ "}</Typography>Campuses</Typography>
            <div class="logos">
              <div class="logos-slide">
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimb.png" alt="iimb" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimraipur.png" alt="iimraipur" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iima.png" alt="iima" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iiml.png" alt="iiml" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimc.png" alt="iimc" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimk.png" alt="iimk" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iims.png" alt="iims" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimu.png" alt="iimu" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimrohtak.png" alt="iimrohtak" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimt.png" alt="iimt" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimranchi.png" alt="iimranchi" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimkashipur.png" alt="iimkashipur" />
              </div>

              <div class="logos-slide">
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimb.png" alt="iimb" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimraipur.png" alt="iimraipur" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iima.png" alt="iima" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iiml.png" alt="iiml" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimc.png" alt="iimc" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimk.png" alt="iimk" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iims.png" alt="iims" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimu.png" alt="iimu" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimrohtak.png" alt="iimrohtak" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimt.png" alt="iimt" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimranchi.png" alt="iimranchi" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimkashipur.png" alt="iimkashipur" />
              </div>

              <div class="logos-slide">
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimb.png" alt="iimb" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimraipur.png" alt="iimraipur" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iima.png" alt="iima" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iiml.png" alt="iiml" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimc.png" alt="iimc" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimk.png" alt="iimk" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iims.png" alt="iims" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimu.png" alt="iimu" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimrohtak.png" alt="iimrohtak" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimt.png" alt="iimt" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimranchi.png" alt="iimranchi" />
                <img src="https://ccicons.s3.amazonaws.com/mba_hires/colleges/iimkashipur.png" alt="iimkashipur" />
              </div>
            </div>
            <Box component={"div"} sx={{
              overflow: "hidden",
              height: "100%",
              width: "80vw",
              position: "relative",
            }} mt={4} />
          </Box>
        </Box>
      </Box>
      <Box py={1} component={"div"} sx={{ backgroundColor: "white", borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }} >
        <Divider>
          <Typography textAlign={{xs:"center"}} color={"text.secondary"} my={5} variant='h6'>Our Stats Speak Volumes</Typography>
        </Divider>
        <Box mx={3} my={1} p={2} bgcolor={"white"} alignItems={"center"} justifyContent={"space-evenly"} gap={2} display={"flex"} flexDirection={{ xs: "column", md: "row" }} borderRadius={5}>
          <Box sx={{textAlign:{xs:"center"}}}>
            <Typography variant='h3' color={Colors.green}>20,000+</Typography>
            <Typography color={"text.secondary"}>B-School Candidates</Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box sx={{textAlign:{xs:"center"}}}>
            <Typography variant='h3' color={Colors.green}>100+</Typography>
            <Typography color={"text.secondary"}>Top NIRF B-School</Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box sx={{textAlign:{xs:"center"}}}>
            <Typography variant='h3' color={Colors.green}>50+</Typography>
            <Typography color={"text.secondary"}>Recruiters Assisted</Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box sx={{textAlign:{xs:"center"}}}>
            <Typography variant='h3' color={Colors.green}>100+</Typography>
            <Typography color={"text.secondary"}>Industry - Role Combinations</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Clients