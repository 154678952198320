import { Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import React from 'react'
import EmploymentTemplate from '../../components/Employment/EmploymentTemplate'

export default function RemoteIntern() {
    const horizontalContainer = [
        {
            title: "Who can Apply?",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/student.svg",
            subtitle: "2nd Year Students"
        },
        {
            title: "What they’ll Earn?",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/cash.svg",
            subtitle: "0 to 5k/ month pay"
        },
        {
            title: "When?",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/calender.svg",
            subtitle: "1st Year: July to Mar\n2nd Year: July to Jan"
        },
        {
            title: "Where?",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/location.svg",
            subtitle: "Remote"
        },
    ]
    return (
        <EmploymentTemplate
            title="Remote Intern"
            heading="Want to Recruit Live Project MBA Interns for Small Tasks?"
            image="https://ccicons.s3.amazonaws.com/mba_hires/landing_page/remote_intern_main.png"
            paragraph={<Typography variant='body1' color={grey[600]}>Live project internships or remote internships give students the chance to contribute to real-world projects while balancing their academic and extracurricular commitments. Recruiters benefit by accessing student talent for short-term tasks without extensive training or supervision. These internships typically last between 1 to 6 months, with students dedicating 10-12 hours per week, providing year-round support to organizations.
                <br />
                <br />
                Students can also leverage the expertise of co-opted professors to tackle complex problems. They opt for these opportunities to enhance their resumes, gain exposure to domain-specific projects, and align their skills with recruiters' needs, ensuring comprehensive assistance.
            </Typography>}
            horizontalContainer={horizontalContainer}
            footer={<Typography variant='body1' color={grey[600]} my={1}>Maximize recruitment efficiency by offering summer internships to promising live project interns, ensuring a seamless transition to full-time roles and maximum productivity from day one.</Typography>}
        />
    )
}
