import { Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import React from 'react'
import EmploymentTemplate from '../../components/Employment/EmploymentTemplate'

export default function SummerIntern() {
    const horizontalContainer = [
        {
            title: "Who can Apply?",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/student.svg",
            subtitle: "1st and 2nd Year Students"
        },
        {
            title: "What they’ll Earn?",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/cash.svg",
            subtitle: "10k-80k/month"
        },
        {
            title: "When?",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/calender.svg",
            subtitle: "April-July"
        },
        {
            title: "Where?",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/location.svg",
            subtitle: "April-July | In-Office/Remote"
        },
    ]
    return (
        <EmploymentTemplate
            title="Summer Intern"
            heading="Seeking Ways to Maximize Talent, Innovate, and Elevate Your Brand on a Budget?"
            image='https://ccicons.s3.amazonaws.com/mba_hires/landing_page/summer_interns_main.png'
            paragraph={<Typography variant='body1' color={grey[600]}>After completing the first year of the MBA program, students embark on a summer internship. These internships provide invaluable corporate experience, often with compensation, and typically last 2-3 months between April and July. Students apply their first-year learning and skills to real-world business challenges, managing projects related to specific area that contribute to the company's growth.
                <br />
                <br />
                Summer interns inject fresh ideas, reduce workload, and enhance your brand's appeal, while gaining hands-on experience in specific roles or domains of interest. This symbiotic arrangement ensures recruiters achieve their goals effectively while students gain essential real-world knowledge and skills.
            </Typography>}
            horizontalContainer={horizontalContainer}
            footer={<Typography variant='body1' color={grey[600]} my={{xs:0,md:1}}>Secure top talent early by hiring Summer Interns between Sep-Dec for next year's Apr-Jul, and offering Pre-Placement Offers to ensure seamless transition to full-time roles.</Typography>}
        />
    )
}
