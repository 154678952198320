import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Colors } from '../../constants/color'
import './CardContainer.css'
import { ArrowForward } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

function CardContainer() {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [cards, setCards] = useState([
    {
      img: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/card1.png",
      title: "Summer Interns",
      href: "/summer-intern",
      subtitle: "Hire MBA interns for 2-3 months to solve complex business problems with innovative and impactful outcomes."
    },
    {
      img: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/card2.png",
      title: "Remote Interns",
      href: "/remote-intern",
      subtitle: "Hire 1st/2nd-year MBA interns (10-12 hrs/week) remotely for tasks like lead gen, data analysis, etc., at little or no cost."
    },
    {
      img: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/card3.png",
      title: "Full-time Employee",
      href: "/full-time-employee",
      subtitle: "Fuel growth with top MBA graduates, leveraging their business acumen, problem-solving skills, and leadership potential."
    },
  ])

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"} mt={{xs:8,md:0}}>
        <Box display={"flex"} justifyItems={"center"} alignItems={"center"} gap={1}>
          <Typography variant='h5' mt={2} mb={1} sx={{ height: "40px" }}>Looking for </Typography>
          <div className='inner-headings'>
            <span>
              Summer Interns? <br />
              Remote Interns? <br />
              Full-Time Roles? <br />
            </span>
          </div>
        </Box>
        <Typography variant='body1' color={"text.secondary"}>We've got you covered with unmatched success!</Typography>
      </Box>
      <Box display={"flex"} justifyContent={"space-between"} m={2} gap={5} flexDirection={{xs:"column",md:"row"}}>
        {cards.map((card, index) => {
          return (
            <Card
              key={index}
              sx={{
                flex: 1,
                borderRadius: "16px",
                transition: isMobile ? "none" : "transform 0.3s ease, box-shadow 0.3s ease",
                transform: isMobile ? "none" : (hoveredIndex === index ? "translateY(-40px)" : "translateY(0)"),
                boxShadow: isMobile ? "none" : (hoveredIndex === index ? "0px 4px 20px rgba(0,0,0,0.2)" : "none"),
                height: { xs: "auto", md: "500px" },
                width: { xs: "100%", md: "auto" },
                marginBottom: { xs: 2, md: 0 },
                backgroundColor: { xs: "#ffe6e6", md: "white" },
                padding: { xs: 1, md: 0 },
                display: 'flex',
                flexDirection: 'column',
              }}
              onMouseEnter={() => !isMobile && setHoveredIndex(index)}
              onMouseLeave={() => !isMobile && setHoveredIndex(null)}
            >
              <CardActionArea
                onClick={() => (window.location.href = card.href)}
                sx={{
                  "&:hover": {
                    backgroundColor: isMobile ? "transparent" : Colors.primaryLight.concat("50"),
                    "& .know-more": {
                      display: isMobile ? "none" : "flex",
                      color: Colors.primary,
                    },
                  },
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  gap: 2
                }}
              >
                <CardMedia component="img" height={{ xs: "auto", md: "360px" }} image={card.img} alt={card.title} sx={{ borderRadius: "4px" }} />
                <CardContent sx={{ height: { xs: "auto", md: "100px" },padding:{xs:0} }} textAlign={{xs:"left"}}>
                  <Typography gutterBottom variant="h5" component="div" sx={{ color: Colors.primary, display: 'flex', alignItems: 'center' }}>
                    {card.title} 
                    {isMobile && <ArrowForward sx={{ marginLeft: 1 }} />}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {card.subtitle}
                  </Typography>
                </CardContent>
                {!isMobile && (
                  <Box
                    className="know-more"
                    height={"40px"}
                    display="none"
                    justifyContent="center"
                    mb={1}
                  >
                    <Button
                      variant="text"
                      sx={{
                        textTransform: "none",
                        backgroundColor: "transparent",
                        color: Colors.primary,
                      }}
                    >
                      <Typography variant="h6">Know More</Typography>
                      <ArrowForward />
                    </Button>
                  </Box>
                )}
              </CardActionArea>
            </Card>
          )
        })}
      </Box>
    </Box>
  )
}

export default CardContainer