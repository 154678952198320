import { Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import React from 'react'
import EmploymentTemplate from '../../components/Employment/EmploymentTemplate'

export default function FullTime() {
    const horizontalContainer = [
        {
            title: "Who can Apply?",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/student.svg",
            subtitle: "2nd Year Students"
        },
        {
            title: "What they’ll Earn?",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/cash.svg",
            subtitle: "40k onwards\n(Varies by College and Role)"
        },
        {
            title: "When?",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/calender.svg",
            subtitle: "Usually after February"
        },
        {
            title: "Where?",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/location.svg",
            subtitle: "In-office/Full Time"
        },
    ]
    return (
        <EmploymentTemplate
            title="Full-Time Employee"
            heading="Do you want full-time MBA grads to help your company grow?"
            image="https://ccicons.s3.amazonaws.com/mba_hires/landing_page/full_time_employee_main.png"
            paragraph={<Typography variant='body1' color={grey[600]}>After completing their two-year MBA course, students seamlessly transition into full-time roles within organizations, equipped with knowledge and practical experience. Recruiters have a prime opportunity to secure top-tier talent as these individuals bring a fresh perspective and innovative solutions, along with unbiased minds that are highly moldable and adaptable to new cultures.
                <br />
                <br />
                Starting at the end of March, they bring new energy to the workplace and can be assigned to specific domains based on needs. This talent infusion boosts team effectiveness and fosters a culture of innovation and growth.</Typography>}
            horizontalContainer={horizontalContainer}
            footer={<Typography variant='body1' color={grey[600]} my={1}>Identifying candidates early ensures ample time for comprehensive training and pre-secures them, aligning them effectively with the organization's goals and culture from the start.</Typography>}
        />
    )
}
