import { KeyboardArrowDown, KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Drawer, MenuItem, ButtonBase } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { blueGrey, grey } from '@mui/material/colors';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { ReactComponent as Logo } from '../../assets/global/logo.svg';
import { Colors } from '../../constants/color';
import { default as PositionAwareButton } from '../Buttons/PositionAware/PositionAwareButton';
import BasicModal from '../Modals/Modal';
import ShineButton from '../Buttons/Shine/ShineButton';

function NavBar() {
    const [hireModal, setHireModal] = React.useState(false)
    const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false)

    const pages = ['Home', <Box display={"flex"} alignItems={"center"}><Typography variant='body2'>Hire</Typography><KeyboardArrowDown sx={{
        transform: hireModal ? 'rotate(180deg)' : 'rotate(0deg)',
        transition: 'transform 0.3s ease-in-out', // Optional: adds a smooth transition effect
    }} /></Box>, 'Contact']
    const hrefs = [() => window.location.href = '/', () => setHireModal(true), () => document.getElementById('contact-us')?.scrollIntoView({ behavior: "smooth" })]

    const types = [
        {
            name: "Summer Interns",
            img: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/summer_intern_header.png",
            href: "/summer-intern"
        },
        {
            name: "Remote Interns",
            img: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/remote_intern_header.png",
            href: "/remote-intern"
        },
        {
            name: "Full-Time Employee",
            img: "https://ccicons.s3.amazonaws.com/mba_hires/landing_page/full_time_employee_header.png",
            href: "/full-time-employee"
        }
    ]

    const handleMobileNavigation = (page) => {
        setMobileMenuOpen(false)
        if (page === 'Contact') {
            document.getElementById('contact-us')?.scrollIntoView({ behavior: "smooth" })
        } else {
            window.location.href = hrefs[page]
        }
    }

    const renderMobileMenu = () => (
        <Drawer
            anchor="left"
            open={mobileMenuOpen}
            onClose={() => setMobileMenuOpen(false)}
            PaperProps={{
                sx: { width: '100%' }
            }}
        >
            <Box sx={{ p: 2, height: '100%', backgroundColor: '#fff' }}>
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <ButtonBase href="/">
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <Box component="img" src='assets/global/logo.png' width="18px" height="18px" sx={{ mr: 1 }} />
                         <Typography
                             noWrap
                             sx={{
                                 fontFamily: "Jura",
                                fontWeight: "700",
                                color: Colors.primary,
                            }}
                            >
                            MBAHIRES.COM
                            </Typography>
                    </Box>
                    </ButtonBase>
                    <IconButton
                        sx={{mr:1}}
                        onClick={() => setMobileMenuOpen(false)}
                        >
                        <CloseIcon />
                    </IconButton>
                    </Box>
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>Hire</Typography>
                {types.map((type) => (
                    <MenuItem 
                        key={type.name} 
                        onClick={() => { window.location.href = type.href; setMobileMenuOpen(false); }}
                        sx={{ 
                            borderBottom: '1px solid #e0e0e0',
                            px: 0
                        }}
                    >
                        {type.name}
                    </MenuItem>
                ))}

                <MenuItem 
                    onClick={()=>handleMobileNavigation('Contact')}
                    sx={{ 
                        py: 2,
                        px:0,
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "24px"
                        }}
                    >
                        Contact
                    </Typography>
                    <KeyboardArrowRight sx={{mr:1}} />
                </MenuItem>
            </Box>
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column" } height={"167px"} bgcolor={" #FAFAFA"}>
                <Typography color={"text.secondary"} textAlign={"center"} typography={"h6"} fontSize={"16px"} fontWeight={"400"}>
                Access to NIRF B-School Candidates
                </Typography>
                <ShineButton
                href={"https://recruiter.careercarve.in"}
                sx={{ mx:1,px: "32px", my: "12px", py: "12px", backgroundColor: Colors.blue, borderRadius: 10, textTransform: 'none', "&:hover": { backgroundColor: blueGrey["900"] } }} >
                <Typography variant='h6' fontSize={"16px"} fontWeight={"500"}>
                    Try for FREE
                </Typography>
                </ShineButton>
            </Box>
        </Drawer>
    )

    return (
        <AppBar position="static" color="background" elevation={0} sx={{ borderBottom: { xs: 0, md: 1 }, borderColor: Colors.primaryLight}}>
            <Container maxWidth="2xl">
                <Toolbar disableGutters>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, alignItems: "center" }} >
                        <Logo width={36} height={36} />
                    </Box>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: "Jura",
                            fontWeight: 700,
                            color: Colors.primary,
                            textDecoration: 'none',
                        }}
                    >
                        MBAHIRES.COM
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, alignItems: 'center', justifyContent:{xs:"space-between"} }}>
                        <IconButton
                            aria-label="open drawer"
                            onClick={() => setMobileMenuOpen(true)}
                            sx={{ mr: 'auto' }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Box display="flex" justifyContent="center" alignItems="center" sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                            <ButtonBase href="/">
                            <Box component="img" src='assets/global/logo.png' width="18px" height="18px" sx={{ mr: 1 }} />
                            <Typography
                                noWrap
                                sx={{
                                    fontFamily: "Jura",
                                    fontWeight: "700",
                                    color: Colors.primary,
                                }}
                                >
                                MBAHIRES.COM
                            </Typography>
                            </ButtonBase>
                        </Box>
                    </Box>
                    <Box justifyContent={"flex-end"} sx={{ flexGrow: 1, gap: "16px", display: { xs: 'none', md: 'flex', } }}>
                        {pages.map((page, index) => (
                            <Button
                                key={index}
                                variant='text'
                                onClick={hrefs[index]}
                                sx={{
                                    my: 2,
                                    color: grey[700],
                                    display: 'block',
                                    backgroundColor: "transparent",
                                    textTransform: "none",
                                    position: 'relative',
                                    '&:hover::after': {
                                        content: '""',
                                        position: 'absolute',
                                        width: '100%',
                                        height: '2px', // Adjust the thickness of the underline
                                        backgroundColor: Colors.primary, // Adjust the color of the underline
                                        bottom: '-4px', // Adjust the space between the text and underline
                                        left: 0,
                                    },
                                }}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 0, ml: "56px", display: { xs: 'none', md: 'flex' } }}>
                        <PositionAwareButton target={"https://recruiter.careercarve.in"} text="Get Started" />
                    </Box>
                </Toolbar>
                {renderMobileMenu()}
                <BasicModal open={hireModal} setOpen={setHireModal} style={{
                    position: 'absolute',
                    top: '30%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: "20px",
                    bgcolor: "#FF725E",
                    boxShadow: 24,
                    p: 4,
                }} >
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} gap={"72px"} px={"32px"}>
                        {types.map((type) =>
                            <ButtonBase key={type.name} href={type.href}>
                                <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    sx={{
                                        backgroundColor: "transparent",
                                        borderRadius: "10px",
                                        '&:hover': {
                                            backgroundColor: "white",
                                            pb: 1,
                                            '& .hover-text': {
                                                color: Colors.primary,
                                            },
                                        },
                                    }}
                                >
                                    <Box component={"img"} src={type.img} sx={{ borderRadius: "10px" }} />
                                    <Typography
                                        variant='h6'
                                        color={"white"}
                                        className="hover-text"
                                        mt={1}
                                    >
                                        {type.name}
                                    </Typography>
                                </Box>
                            </ButtonBase>)}
                    </Box>
                </BasicModal>
            </Container>
        </AppBar>
    );
}

export default NavBar;
