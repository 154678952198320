import { Box, Card, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Colors } from '../../constants/color'

function PartnerBrands() {
    const [companies, setCompanies] = useState([
        {
            name: "Wheels India Limited",
            img: "https://ccicons.s3.amazonaws.com/mba_hires/companies/wheels_india.png"
        },
        {
            name: "CavinKare",
            img: "https://ccicons.s3.amazonaws.com/mba_hires/companies/CavinKare.png"
        },
        {
            name: "Bosch",
            img: "https://ccicons.s3.amazonaws.com/mba_hires/companies/bosch.png"
        }, {
            name: "Wells Fargo",
            img: "https://ccicons.s3.amazonaws.com/mba_hires/companies/wells_fargo.png"
        }, {
            name: "BlackBuck Engineers",
            img: "https://ccicons.s3.amazonaws.com/mba_hires/companies/blackbuck.png"
        }, {
            name: "Succeed Technologies",
            img: "https://ccicons.s3.amazonaws.com/mba_hires/companies/suceed_technologies.png"
        }, {
            name: "Tutornex",
            img: "https://ccicons.s3.amazonaws.com/mba_hires/companies/tutorNex.png"
        }, {
            name: "Dhanani & Co",
            img: "https://ccicons.s3.amazonaws.com/mba_hires/companies/Dhanani.png"
        }, {
            name: "Dashloc",
            img: "https://ccicons.s3.amazonaws.com/mba_hires/companies/Dashloc.png"
        }, {
            name: "Kosmoderma",
            img: "https://ccicons.s3.amazonaws.com/mba_hires/companies/kosmoderma.png"
        }, {
            name: "Pink Lemonade",
            img: "https://ccicons.s3.amazonaws.com/mba_hires/companies/pink_lemonade.png"
        }, {
            name: "Stealthmode",
            img: "https://ccicons.s3.amazonaws.com/mba_hires/companies/stealthmode.png"
        },
    ])
    return (
        <Box bgcolor={"#F5F5F5"} p={1}>
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"} mx={{xs:2}}>
                <Typography variant={{xs:"h6",md:"h5"}} mt={2} mb={1} align="center" fontWeight={{xs:"500"}} fontSize={{xs:"20px",md:"auto"}}>Collaborative Excellence With Brands</Typography>
                <Typography variant='body1' color={"text.secondary"} align="center">Explore brands benefiting from our expertise</Typography>

                <Box display={"flex"} flexWrap={"wrap"} my={4} rowGap={{xs:"16px",md:"48px"}} columnGap={{xs:"16px",md:"34px"}} justifyContent={{xs:"center",md:"center"}} mx={{xs:0,md:4}} width={"100%"}>
                    {companies.slice(0, 8).map((company, index) =>
                        <Card key={index} sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: { xs: "153px", md: "200px" }, height: { xs: "72px", md: "120px" }, boxShadow: `0px 2px 16px 0px rgb(0,0,0,0.1)`, borderRadius: "8px", }}>
                            <Box component={"img"} src={company.img} sx={{ width:{xs:"48px",md:"auto"}, height:{xs:"50px",md:"auto"}, objectFit: "contain" }} />
                        </Card>
                    )}     
                </Box>
                <Typography variant='body1' color={"text.secondary"} align="center">and more...</Typography>
            </Box>
        </Box>
    )
}

export default PartnerBrands