import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  // useLocation,
  useParams,
} from "react-router-dom";
import Landing from "./screens/Landing/Landing";
import NavBar from "./components/Nav/NavBar";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import Footer from "./components/Footer/Footer";
import SummerIntern from "./screens/SummerIntern/SummerIntern";
import RemoteIntern from "./screens/RemoteIntern/RemoteIntern";
import FullTime from "./screens/FullTime/FullTime";

function App() {
  const theme = createTheme({
    palette: {
      mode: 'light', // Locking the theme to light mode
      primary: {
        main: '#FF725E',
      },
      secondary: {
        main: '#FFC4BD',
      },
      background: {
        default: '#ffffff', // Default background color for light mode
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: '#FF725E', // Default primary background color
            color: '#ffffff', // White text color
            // '&:hover': {
            //   backgroundColor: '#d35452', // Darker shade for hover state
            // },
          },
        },
      },
      MuiTypography:{
        styleOverrides:{
          root:{
            fontFamily:"Inter"
          }
        }
      }
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/summer-intern" element={<SummerIntern />} />
          <Route path="/remote-intern" element={<RemoteIntern />} />
          <Route path="/full-time-employee" element={<FullTime />} />
        </Routes>
        <Footer/>
      </Router>
    </ThemeProvider>
  );
}

export default App;
