import { Typography, Box, Button } from '@mui/material'
import React from 'react'
import { ReactComponent as Logo } from '../../assets/global/logo.svg'
import { Colors } from '../../constants/color'
import ShineButton from '../../components/Buttons/Shine/ShineButton'
import { blue, blueGrey } from '@mui/material/colors'

function Hero() {
  return (
    <Box position={'relative'} gap={5} justifyContent={"center"} alignItems={"center"} display={"flex"} flexDirection={"column"} height={{xs:"80vh",md:"75vh"}}>
      {/* Background Shapes */}
      <Box position={'absolute'} top={0} left={0} right={0} bottom={0} zIndex={-1} overflow={'hidden'} display={{ xs: 'block', sm: 'none' }}>
        <Box component={'img'} src={'https://ccicons.s3.amazonaws.com/mba_hires/landing_page/hero-1.svg'} alt={'Background shape 1'} sx={{ position: 'absolute', top:"7%",  right: '18%', width: '30%', height: 'auto' }} />
        <Box component={"img"} src={"https://ccicons.s3.amazonaws.com/mba_hires/landing_page/hero-2.svg"} alt={"Background shape 2"} sx={{ position: "absolute", top: "28%", left: "8%", width: "20%", height: "auto" }} />
        <Box component={'img'} src={'https://ccicons.s3.amazonaws.com/mba_hires/landing_page/hero-3.svg'} alt={'Background shape 3'} sx={{ position: 'absolute', top: '37%', right: '10%', width: '18%', height: 'auto' }} />
      </Box>

      {/* TOP HEADER */}
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={{ md: "row", xs: "column" }} pl={{ xs: 0, md: 0 }}>
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"center"} sx={{textAlign:{xs:"left"}}} >
          <Typography width={{ md: "100%", xs: "38%" }} typography={{ md: 'h3', xs: "h2" }} fontSize={{ xs: "56px" }} fontWeight={{ xs: 500 }}>
            Discover Skilled MBA Talents
          </Typography>
          <Box
            component="img"
            mx={{ md: 1, xs: 0 }}
            mt={{ xs: 10, md: 0 }}
            sx={{
              width: "128px", /* you can use % */
              height: "auto"
            }}
            alt="The house from the offer."l
            src="https://ccicons.s3.amazonaws.com/mba_hires/landing_page/hero.png"
          />
          <Box
            component="img"
            display={{ md: "none", xs: "block" }}
            sx={{
              width: "5%", /* you can use % */
              height: "auto"
            }}
            alt="The house from the offer."
            src="https://ccicons.s3.amazonaws.com/mba_hires/landing_page/three_strokes.svg"
          />
        </Box>
        <Typography  typography={{ md: 'h3', xs: "h2" }} fontSize={{ xs: "56px" }} fontWeight={{ xs: 500 }} mr={{ xs: "5%",md:"0" }}>
          Effortlessly!
        </Typography>
        <Box
          component="img"
          display={{ md: "block", xs: "none" }}
          sx={{
            alignSelf: "flex-start",
            width: "24px", /* you can use % */
            height: "auto"
          }}
          alt="The house from the offer."
          src="https://ccicons.s3.amazonaws.com/mba_hires/landing_page/three_strokes.svg"
        />
      </Box>
      {/* LOGO */}
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} >
        <Box component={"img"} src='assets/global/logo.png' width={{ md: "58px", xs: "36px" }} height={{ md: "58px", xs: "36px" }} />
        <Typography
          noWrap
          mx={{ md: 2, xs: 1 }}
          sx={{
            mr: 2,
            typography: { xs: "h5", md: "h4" },
            fontFamily: "Jura",
            fontWeight: "bold",
            color: Colors.primary,
          }}
        >
          MBAHIRES.COM
        </Typography>
      </Box>
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={{ md: "row", xs: "column" }}>
        <Typography color={'text.secondary'} sx={{ textAlign: { md: "left", xs: "center" }, typography: { md: "h5", xs: "h6" } }}>
          Access to NIRF B-School Candidates
        </Typography>
        <ShineButton
          href={"https://recruiter.careercarve.in"}
          sx={{ mx: 1, px: "32px", my: "12px", py: "12px", backgroundColor: Colors.blue, borderRadius: 10, textTransform: 'none', "&:hover": { backgroundColor: blueGrey["900"] } }} >
          <Typography variant='h6'>
            Try for FREE
          </Typography>
        </ShineButton>
      </Box>
    </Box>
  )
}

export default Hero