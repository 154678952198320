import { Button } from '@mui/material'
import React from 'react'
import './shine.css'

function ShineButton({ sx, children, href }) {
  return (
    <Button sx={sx} id='shine' href={href}>
      {children}
    </Button>
  )
}

export default ShineButton