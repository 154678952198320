import { Box, Button, Divider, Typography } from '@mui/material'
import React from 'react'
import { Colors } from '../../constants/color'
import { blue, grey } from '@mui/material/colors'

export default function EmploymentTemplate({ title, heading, paragraph, horizontalContainer, footer, image }) {
    return (
        <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} alignItems={"center"} mt={{xs:2,md:8}} mx={{xs:2,md:8}} gap={{xs:2,}}>
            <Typography bgcolor={"#FFE4E0"} variant='body2' sx={{ padding: "8px 32px", justifyContent: "center", alignItems: "center", gap: "8px", borderRadius: "8px" }} fontSize={{xs: "18px"}} fontWeight={{xs:500}}>{title}</Typography>
            <Typography variant='h4' maxWidth={"720px"} my={{ md: "16px"}} textAlign={"center"} fontSize={{xs: "22px", md: "34px"}} fontWeight={{xs:500}}>{heading}</Typography>
            <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} gap={{xs:2}}>
                <Box my={{xs:0,md:1}} component={"img"} src={image}  borderRadius={"8px"} height={{xs:"140px",md:"auto"}} width={{xs:"100%",md:"auto"}}/>

                {paragraph}

                <Box display={"flex"} flexDirection={{xs:"column",md:"row"}} justifyContent={"space-evenly"} my={5}  gap={{xs:"36px"}}>
                    {horizontalContainer.map((item) =>
                        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} >
                            <Typography sx={{ px: 1, borderRadius: "5px", backgroundColor: "#2183C0", color: "white", mb: 1 }} textAlign={"center"} fontSize={{xs:"18px"}} fontWeight={{xs:500 }}>{item.title}</Typography>
                            <Box p={{xs:"16px",md:1.5}} display={"flex"} flexDirection={"column"} alignItems={"center"} gap={"9px"}>
                            <Box display={"flex"}  bgcolor={"#E0EFFA"} borderRadius={"50%"} p={{xs:"8px",md:"12px"}}>
                                <Box component={"img"} src={item.icon}/>
                            </Box> 
                            <Typography variant='body2' color={'text.secondary'} textAlign={"center"} sx={{ whiteSpace: 'pre-line' }} fontSize={{xs:"14px"}}>{item.subtitle}</Typography>
                            </Box>
                        </Box>
                    )}
                </Box>

                <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} alignItems={"self-start"} gap={{xs:1}}>
                    <Typography color={grey[700]} sx={{ borderBottom: 2, borderColor: Colors.blue }} fontSize={{xs:"18px"}} fontWeight={{xs:500}}>Pro Tip</Typography>
                {footer}
                </Box>


                <Box sx={{ display: "flex", justifyContent: "center" }} my={{xs:1}}>
                    <Button
                        href={"https://recruiter.careercarve.in"}
                        sx={{
                            my: 2,
                            textTransform: "none",
                            p: { xs: "16px  32px", md: 2 },
                            borderRadius: 10,
                            width: { xs: "164px", md: "auto" },
                            height: { xs: "48px", md: "auto" },
                            '&:hover': {
                                backgroundColor: '#d35452', // Darker shade for hover state
                            },
                        }}
                    >
                        <Typography variant='body2' fontWeight={{xs:400,md:500}} fontSize={{xs:"18px"}}>Get Started</Typography>
                    </Button>
                </Box>
                <Divider/>
            </Box>
        </Box>
    )
}
