import { Box, Button, Card, Typography } from '@mui/material'
import { grey, red } from '@mui/material/colors';
import React, { useState } from 'react'
import { Colors } from '../../constants/color';

function UsVsOthers() {
    const [us, setUs] = useState([
        {
            title: "Post a Job",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/why_us/mh_post_a_job.svg",
            subtitle: "Use AI-tailored job descriptions and industry insights to make precise hiring decisions faster.",
            align: "left",
        },

        {
            title: "Manually Schedule Interviews",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/why_us/mh_interview.svg",
            subtitle: "Interview the top-ranked candidates who cleared our multiple role-specific assessment tests to improve hiring.",
            align: "right",
        },
        {
            title: "Final Selection",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/why_us/handshake.svg",
            subtitle: "Craft your ideal team by meticulously selecting top interviewees for a seamless fit.",
            align: "",
        },
    ])
    const [others, setOthers] = useState([
        {
            title: "Post a Job",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/why_us/traditional_post_a_job.svg",
            align: "left",
        },
        {
            title: "Prepare Job Description",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/why_us/prepare_job_description.svg",
            align: "right",
        },
        {
            title: "Screen 1000s of Resumes",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/why_us/Screen_1000_resumes.svg",
            align: "left",
        },
        {
            title: "Manually Schedule Interviews",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/why_us/traditional_interview.svg",
            align: "right",
        },
        {
            title: "Conduct multiple shortlist rounds",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/why_us/conduct_multiple_shortlist_rounds.svg",
            align: "left",
        },
        {
            title: "Access, Interview, Re-evaluate",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/why_us/access_interview_revaluate.svg",
            align: "right",
        },
        {
            title: "Final Selection",
            icon: "https://ccicons.s3.amazonaws.com/mba_hires/why_us/handshake.svg",
            align: "",
        },
    ]);

    return (
        <Box display={"flex"} justifyContent={"center"} alignItems={{xs:"center", md:"flex-start"}} flexDirection={{xs:"column", md:"row"}} gap={8} py={8} bgcolor={"white"}>
            <Box width={{ xs: "100%", md: "596px" }} p={2} borderRadius={2} display={"flex"} flexDirection={"column"} alignItems={{ xs: "center", md: "flex-start" }}>
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} bgcolor={grey[200]} color={grey[800]} px={3} py={"10px"} borderRadius={1}>
                    <Typography variant='body2' fontWeight={{xs:"500"}} fontSize={{xs:"20px"}}>Traditional Hiring Process</Typography>
                </Box>
                <Box my={2} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                    {others.map((other, index) =>
                        <React.Fragment key={index}>
                            <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                                <Box sx={{ width: "90px", height: "90px", borderColor: grey[400] }} component={"img"} src={other.icon} border={0.5} borderRadius={"50%"} />
                                <Typography mt={1} color={grey[700]} textAlign="center">{other.title}</Typography>
                            </Box>
                            {other.align && (
                                <Box 
                                    component={"img"} 
                                    mt={2} // Add top margin to create gap
                                    mb={2} // Add bottom margin to create gap
                                    src={`https://ccicons.s3.amazonaws.com/mba_hires/why_us/arrow_${other.align}.svg`} 
                                />
                            )}
                        </React.Fragment>
                    )}
                </Box>
            </Box>
            <Box display={"flex"} flexDirection={"column"} alignItems={"center"} sx={{ width: {xs:"100%", md:"596px"}, p: "16px", boxShadow: { xs: "none", md: `0px 4px 20px 0px ${Colors.primaryLight}` }, backgroundColor: "transparent", borderRadius: "16px" }} raised>
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} bgcolor={"#FFE4E0"} color={Colors.primary} px={3} py={"10px"} borderRadius={1}>
                    <Typography variant='body2' fontWeight={{xs:"500"}} fontSize={{xs:"20px"}}>How we work?</Typography>
                </Box>
                <Box my={2} display={"flex"} flexDirection={"column"} alignItems={{ xs: "center", md: "flex-start" }}>
                    {us.map((other, index) =>
                        <React.Fragment key={index}>
                            <Box display={"flex"} flexDirection={"row"} alignItems={"center"} mb={2}>
                                <Box sx={{ width: "90px", height: "90px", borderColor: Colors.yellow.concat("30") }} component={"img"} src={other.icon} border={0.5} borderRadius={"50%"} />
                                <Box textAlign={ "left"} mt={{ xs: 2, md: 0 }}>
                                    <Typography mx={2}>{other.title}</Typography>
                                    <Typography mx={2} variant='body2' color={"text.secondary"}>{other.subtitle}</Typography>
                                </Box>
                            </Box>
                            {other.align && <Box component={"img"} ml={{ xs: 0, md: "150px" }} src={`https://ccicons.s3.amazonaws.com/mba_hires/why_us/arrow_${other.align}.svg`} />}
                        </React.Fragment>
                    )}
                </Box>
                <Box display={"flex"} justifyContent={"center"}>
                    <Button
                        href={"https://recruiter.careercarve.in"}
                        sx={{
                            px: "48px" ,py:"16px", borderRadius: 10, textTransform: "none", '&:hover': {
                                backgroundColor: '#d35452', // Darker shade for hover state
                            },
                        }}>
                        <Typography variant='body2' fontWeight={"medium"}>Get Started</Typography>
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default UsVsOthers