import { Button } from '@mui/material';
import React from 'react';
import { styled } from '@mui/system';
import { Colors } from '../../../constants/color';

const StyledButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '20px',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  border: `1px solid ${Colors.primary}`,
  textTransform: "none",
  color: Colors.primary,
  backgroundColor: 'transparent',
  transition: 'color 0.6s',
  '& span': {
    position: 'relative',
    zIndex: 2,
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '300%',
    height: '300%',
    backgroundColor: Colors.primary,
    borderRadius: '50%',
    transform: 'translate(-50%, 0%) scale(0)',
    transition: 'transform 1s ease',
    zIndex: 1,
  },
  '&:hover:before': {
    transform: 'translate(-50%, -50%) scale(1)',

  },
  '&:hover': {
    border: `1px solid transparent`,
    color: '#fff',
  },
}));

function PositionAwareButton({ text, onClick, target }) {
  return (
    <StyledButton onClick={onClick} href={target}>
      <span style={{ fontWeight: "medium" }}>{text}</span>
    </StyledButton>
  );
}

export default PositionAwareButton;
