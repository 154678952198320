import { Box, Button, ButtonBase, Card, Divider, TextField, Typography } from '@mui/material'
import { blueGrey, grey } from '@mui/material/colors'
import React, { useState } from 'react'
import { Colors } from '../../constants/color'
import { FacebookTwoTone, LinkedIn, X } from '@mui/icons-material'

function Footer() {
    const [details, setDetails] = useState()

    return (
        <Box px={{ xs: 0, sm: 4, md: 8, lg: 16 }} py={4} raised display={"flex"} flexDirection={{ md: "column", xs: "column-reverse" }} id="contact-us">
            <Box display={"flex"} flexDirection={{ xs: "column-reverse", md: "row" }} justifyContent={"space-between"}>
                <Box display={{ md: "none", xs: "flex" }} justifyContent={"center"} alignItems={"center"} gap={2}>
                    <Button variant="text" sx={{ backgroundColor: "transparent", color: grey[800], textTransform: 'none', }}>Privacy Policy</Button>
                    <Divider orientation="vertical" flexItem />
                    <Button variant="text" sx={{ backgroundColor: "transparent", color: grey[800], textTransform: 'none', }}>Terms of Service</Button>
                </Box>
                <Box display={"flex"} flexDirection={"column"} alignItems={{ md: "flex-start", xs: "center" }} mb={{ xs: 4, md: 0 }}>
                    <Button href='/' variant="text" sx={{ backgroundColor: "transparent", color: grey[800], textTransform: 'none', fontSize: { xs: 24 } }}>Home</Button>
                    <Button href='/summer-intern' variant="text" sx={{ backgroundColor: "transparent", color: grey[800], textTransform: 'none', fontSize: { xs: 24 } }}>Summer Intern</Button>
                    <Button href='/remote-intern' variant="text" sx={{ backgroundColor: "transparent", color: grey[800], textTransform: 'none', fontSize: { xs: 24 } }}>Remote Intern</Button>
                    <Button href='/full-time-employee' variant="text" sx={{ backgroundColor: "transparent", color: grey[800], textTransform: 'none', fontSize: { xs: 24 } }}>Full-Time Employee</Button>
                </Box>
                <Box
                    p={2}
                    my={4}
                    bgcolor={{ md: "#F5F5F5", xs: "transparent" }} // Set to transparent since we're using a gradient
                    borderRadius={2}
                    width={{ xs: "100%", md: "593px" }}
                    sx={{
                        backgroundImage: {
                            xs: `linear-gradient(to bottom, ${Colors.primaryLight}, white)`,
                            md: "none", // No gradient on medium and up
                        },
                    }}
                >
                    <Typography variant="h6">
                        Get in{" "}
                        <Box component="span" sx={{ color: Colors.primary }}>
                            Touch!
                        </Box>
                    </Typography>
                    <Typography variant="caption" color={"text.secondary"} mb={2}>
                        We're just a message away, ready to help.
                    </Typography>
                    <Box
                        component="form"
                        id="contact-us"
                        noValidate
                        mt={2}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={{ xs: "column", sm: "row" }}
                            gap={2}
                            mb={2}
                        >
                            <TextField
                                label="Full Name"
                                variant="outlined"
                                sx={{flex: 1, backgroundColor: "white",borderRadius: '4px', }}
                            />
                            <TextField
                                label="Mobile Number"
                                variant="outlined"
                                sx={{ flex: 1, backgroundColor: "white",borderRadius: '4px', }}
                            />
                        </Box>
                        <TextField
                            label="Email"
                            variant="outlined"
                            type="email"
                            sx={{ mb: 2, backgroundColor: "white",borderRadius: '4px', }}
                        />
                        <TextField
                            label="Type a message"
                            variant="outlined"
                            multiline
                            rows={4}
                            sx={{ backgroundColor: "white",borderRadius: '4px', }}
                        />
                        <Box sx={{ display: "flex", justifyContent: "center" }} my={2}>
                            <Button
                                fullWidth={{ md: false, xs: true }}
                                sx={{
                                    p: 2,
                                    px: 4,
                                    borderRadius: 10,
                                    backgroundColor: Colors.primary,
                                    color: "white",
                                    "&:hover": {
                                        backgroundColor: "#d35452", // Darker shade for hover state
                                    },
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    sx={{ textTransform: "none" }}
                                    fontWeight={"medium"}
                                >
                                    Submit
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box display={"flex"} flexDirection={{ xs: "column", md: "row" }} justifyContent={"space-between"} my={6}>
                <ButtonBase href='/' sx={{ mb: { xs: 4, md: 0 } }}>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Box component={"img"} src='assets/global/logo.png' width={"24px"} height={"24px"} />
                        <Typography
                            variant="body1"
                            noWrap
                            mx={1}
                            sx={{
                                mr: 1,
                                fontFamily: "Jura",
                                fontWeight: "bold",
                                color: Colors.primary,
                            }}
                        >
                            MBAHIRES.COM
                        </Typography>
                    </Box>
                </ButtonBase>

                <Box display={{ md: "flex", xs: "none" }} justifyContent={"center"} alignItems={"center"} gap={2}>
                    <Button variant="text" sx={{ backgroundColor: "transparent", color: grey[800], textTransform: 'none', }}>Terms</Button>
                    <Button variant="text" sx={{ backgroundColor: "transparent", color: grey[800], textTransform: 'none', }}>Privacy</Button>
                </Box>

                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} gap={{xs:"40px",md:2}}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        p={1}
                        borderRadius="50%"
                        border={`1px solid ${Colors.primary}`}// Circular border
                    >
                        <LinkedIn sx={{ color: Colors.primary }} />
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        p={1}
                        borderRadius="50%"
                        border={`1px solid ${Colors.primary}`}
                    >
                        <FacebookTwoTone sx={{ color: Colors.primary }} />
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        p={1}
                        borderRadius="50%"
                        border={`1px solid ${Colors.primary}`}
                    >
                        <X sx={{ color: Colors.primary }} />
                    </Box>
                </Box>

            </Box>
        </Box>
    )
}

export default Footer
